import { takeUntil } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { AsyncVoidSubject, OptionalBehaviorSubject } from '@bp/frontend/rxjs';
import { TelemetryService } from '@bp/frontend/services/telemetry';

import {
	 ThreeDsProcessingAuthenticateResponse, ThreeDsProcessingResultCallbackEvent, ThreeDsProcessingResultCallbackPayload, ThreeDsProcessingResultCallbackPayloadDTO, ThreeDsProcessingSession, ThreeDsProcessingAuthenticateRequest
} from '@bp/three-ds/shared/domains/three-ds-processing';

import { ThreeDsProcessingApiService } from './three-ds-processing-api.service';

@Injectable({
	providedIn: 'root',
})
export class ThreeDsProcessingService {

	private readonly __threeDsProcessingApiService = inject(ThreeDsProcessingApiService);

	private readonly __telemetry = inject(TelemetryService);

	private __session: ThreeDsProcessingSession | null = null;

	get session(): ThreeDsProcessingSession | null {
		return this.__session;
	}

	private __resultCallbackPayload: ThreeDsProcessingResultCallbackPayload | null = null;

	get resultCallbackPayload(): ThreeDsProcessingResultCallbackPayload | null {
		return this.__resultCallbackPayload;
	}

	private readonly __authResponse$ = new OptionalBehaviorSubject<ThreeDsProcessingAuthenticateResponse>();

	readonly authResponse$ = this.__authResponse$.asObservable();

	private readonly __authResultReady$ = new AsyncVoidSubject();

	readonly authResultReady$ = this.__authResultReady$.asObservable();

	constructor() {
		window.bpThreeDsProcessingService = this;
	}

	setSession(session: ThreeDsProcessingSession): void {
		this.__telemetry.setTags({
			checkoutSessionId: session.checkoutSessionId,
			'3ds:transactionId': session.bpTransactionId,
		});

		this.__telemetry.log('3ds authentication session started', { session });

		this.__session = session;
	}

	handleResultCallback(result: ThreeDsProcessingResultCallbackPayloadDTO): void {
		this.__resultCallbackPayload = new ThreeDsProcessingResultCallbackPayload(result);

		this.__telemetry.log(`[handleResultCallback]: Handle result callback ${ this.__resultCallbackPayload.event }`);

		switch (this.__resultCallbackPayload.event) {
			case ThreeDsProcessingResultCallbackEvent.threeDsMethodSkipped:

			case ThreeDsProcessingResultCallbackEvent.threeDsMethodFinished:

			case ThreeDsProcessingResultCallbackEvent.initAuthTimedOut:
				this.__authenticate(this.__resultCallbackPayload);
				break;

			case ThreeDsProcessingResultCallbackEvent.threeDsMethodHasError:
				this.__telemetry.captureMessage(
					'[handleResultCallback]: 3DS method has error',
					this.resultCallbackPayload,
				);

				this.__authResultReady$.complete();
				break;

			case ThreeDsProcessingResultCallbackEvent.authResultReady:
				this.__authResultReady$.complete();
				break;

			default:
				this.__authResultReady$.complete();

				this.__telemetry.captureError(`[handleResultCallback]: Unhandled result callback event: ${ this.__resultCallbackPayload.event }`);
				break;
		}
	}

	monitorAuthResultViaPolling(monitoringUrl: string): void {
		this.__telemetry.log('Monitor 3ds auth result via polling', { monitoringUrl });

		this.__threeDsProcessingApiService
			.pollMonitoringUrlUntilAuthResultReady(monitoringUrl)
			.pipe(takeUntil(this.__authResultReady$))
			.subscribe(() => {
				this.__telemetry.log('Monitor 3ds auth result returned auth result ready');

				void this.__authResultReady$.complete();
			});
	}

	private __authenticate(result: ThreeDsProcessingResultCallbackPayload): void {
		void parent.postMessage(
			{ event: '[bp]:show-processing-stub' },
			'*',
		);

		this.__threeDsProcessingApiService
			.authenticate(new ThreeDsProcessingAuthenticateRequest({
				bpTransactionId: this.__session!.bpTransactionId,
				browserInfo: result.browserInfo,
			}))
			.subscribe(
				{
					next: response => {
						this.__telemetry.log('3ds authentication response', { response });

						void this.__authResponse$.next(response);
					},
					// on any error we return control to backend
					error: () => void this.__authResultReady$.complete(),
				},
			);
	}
}

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		bpThreeDsProcessingService: ThreeDsProcessingService;
	}
}
