<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->

<div cdkTrapFocus cdkTrapFocusAutoCapture class="card-container">
	<section cdkFocusInitial class="card payment-card" [class.submitting]="_isSubmitting$ | async">
		<bp-processing-stub [show]="_isSubmitting$ | async" />

		<form [formGroup]="_form" (ngSubmit)="_submit()">
			<!-- we gotta wait till the form group directive form property got set that underlying
form controls got property updateOn setting -->
			<ng-container *bpDelayedRender>
				<div class="row">
					<div class="left">
						<bp-payment-card-number-input
							label="page.credit-card.number"
							name="cardNumber"
							[required]="true"
							[formControl]="_controls.cardNumber!"
							[pending]="_controls.cardNumber!.pending"
							[skipLuhnValidation]="_environment.isNotProduction"
							(paymentCardBrandChange)="_paymentCardBrand = $event"
						/>

						<div class="row expiry-cvv">
							<bp-payment-card-expire-input
								label="page.credit-card.expiry"
								name="cardExpiryDate"
								[required]="true"
								[formControl]="_controls.cardExpiryDate!"
							/>

							<bp-payment-card-cvv-input
								label="CVV"
								name="cardCvv"
								[paymentCardBrand]="_paymentCardBrand"
								[formControl]="_controls.cardCvv!"
								[required]="true"
							/>
						</div>

						<bp-payment-card-holder-input
							*ngIf="_controls.cardHolderName"
							label="page.credit-card.holder"
							name="cardHolderName"
							[class.no-input-uppercase]="_session.dontRenderCardHolderNameInUppercase"
							[formControl]="_controls.cardHolderName"
							[required]="true"
							[allowAnyCharInValue]="_environment.isStagingOrPreview"
						/>

						<mat-checkbox
							*ngIf="!_session.hideSaveCreditCardCheckbox"
							class="save-card-checkbox"
							color="primary"
							[class.mt-3]="_appService.isNotBrightTheme"
							[formControl]="_controls.saveCard!"
							@slide
						>
							{{
								_appService.isBrightTheme
									? 'Remember my card'
									: ('page.credit-card.save-card-checkbox-caption' | translate)
							}}
						</mat-checkbox>

						<div *ngIf="_globalError" class="global-error mat-error row" @slide>
							{{ _globalError | translate }}
						</div>

						<div *ngIf="_appService.isNotBrightTheme" class="secure-logos">
							<img class="master" src="assets/images/secure/master-card{{ _secureLogosPrefix }}.svg" />
							<img class="visa" src="assets/images/secure/visa{{ _secureLogosPrefix }}.svg" />
							<img src="assets/images/secure/pci{{ _secureLogosPrefix }}.svg" />
							<img src="assets/images/secure/ssl{{ _secureLogosPrefix }}.svg" />
						</div>
					</div>

					<div class="right">
						<div class="credit-detail">
							<button
								class="btn btn-submit"
								type="submit"
								matRipple
								[matRippleDisabled]="_cannotSubmit"
								[class.card-float-button-position]="_appService.isNotBrightTheme"
								[disabled]="_cannotSubmit"
							>
								{{ _session.depositButtonText || 'btn.continue' | translate }}

								<bp-dots-wave *ngIf="_appService.isNotBrightTheme" [show]="_isSubmitting$ | async" />
							</button>
						</div>
					</div>
				</div>
			</ng-container>
		</form>
	</section>
</div>
