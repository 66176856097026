import { Validators } from '@bp/shared/features/validation/models';
import { DTO, MetadataEntity, Validator, Secret } from '@bp/shared/models/metadata';
import { Sensitive } from '@bp/shared/models/core';

export class EncryptCvvRequest extends MetadataEntity {

	@Validator(Validators.sequence([
		Validators.required,
		Validators.string,
		Validators.digits,
		Validators.minLength(3),
		Validators.maxLength(4),
	])!)
	@Secret()
	[Sensitive.cvv]!: string;

	constructor(dto: DTO<EncryptCvvRequest>) {
		super(dto);
	}

}
