import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LanguageSelectComponent } from '@bp/frontend/domains/checkout/components';
import { AppVersionComponent } from '@bp/frontend/components/app-version';
import { DeveloperOverlayPanelComponent } from '@bp/frontend/components/developer-overlay-panel';
import { PaymentCardBrandsSvgIconsDefinitionsComponent } from '@bp/frontend/components/payment-card/brands-svg-icons';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';

import { SharedModule } from '@bp/checkout-frontend/shared';

import { HeaderComponent, FooterComponent, GlobalSpinnerComponent } from './components';
import { RootComponent, ErrorPageComponent, NotFoundPageComponent } from './pages';

const EXPOSED = [
	// containers
	RootComponent,
	ErrorPageComponent,
	NotFoundPageComponent,

	// dumb components
	HeaderComponent,
	FooterComponent,
	GlobalSpinnerComponent,
];

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		RouterModule,
		AppVersionComponent,
		LanguageSelectComponent,
		DeveloperOverlayPanelComponent,
		SharedFeaturesSvgIconsModule,
		PaymentCardBrandsSvgIconsDefinitionsComponent,

		SharedComponentsCoreModule,
		BrowserAnimationsModule,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class CoreModule { }
