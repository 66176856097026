import { Validators } from '@bp/shared/features/validation/models';
import { DTO, Default, Mapper, MetadataEntity, Validator, Secret, PrimitiveValueObject } from '@bp/shared/models/metadata';
import { Sensitive } from '@bp/shared/models/core';

import { PaymentCardTokenType } from './payment-card-token-type.enum';

export class TokenizePaymentCardOutboundRequest extends MetadataEntity {

	@Mapper(PaymentCardTokenType)
	@Validator(Validators.inListStrict(PaymentCardTokenType.getList()))
	@Default(PaymentCardTokenType.multiUse)
	type!: PaymentCardTokenType;

	@Secret()
	@Validator(Validators.required)
	[Sensitive.paymentCardNumber]!: PrimitiveValueObject<string> | string;

	@Validator(Validators.sequence([
		Validators.required,
		Validators.safeInteger,
		Validators.minimum(1),
		Validators.maximum(12),
	])!)
	expireMonth!: number;

	@Validator(Validators.sequence([
		Validators.required,
		Validators.safeInteger,
		Validators.minimum(1950),
		Validators.maximum(2100),
	])!)
	expireYear!: number;

	@Default(null)
	email!: PrimitiveValueObject<string> | string | null;

	@Default(null)
	cardHolderName!: PrimitiveValueObject<string> | string | null;

	@Validator(Validators.sequence([
		Validators.string,
		Validators.digits,
		Validators.minLength(3),
		Validators.maxLength(4),
	])!)
	@Secret()
	@Default(null)
	[Sensitive.cvv]!: string | null;

	constructor(dto: DTO<TokenizePaymentCardOutboundRequest>) {
		super(dto);
	}

}
