import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';

import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { CryptoCurrency, CryptoCurrencyCode, FiatCurrency } from '@bp/shared/models/currencies';
import { ResponseStatusCode } from '@bp/shared/models/core';

import { FADE, SLIDE } from '@bp/frontend/animations';
import { ExternalExtensionService, HostNotifierService } from '@bp/frontend/domains/checkout/services';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { BpError } from '@bp/frontend/models/core';

import { PaymentApiService, AppService } from '@bp/checkout-frontend/providers';
import { CheckoutSession, CryptoTransaction, IApiTransaction, IRequestCryptoDeposit, Transaction } from '@bp/checkout-frontend/models';

const additionalInputFields = <const>[
	'email',
];

@Component({
	selector: 'bp-crypto-page',
	templateUrl: './crypto-page.component.html',
	styleUrls: [ './crypto-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE, SLIDE ],
})
export class CryptoPageComponent extends Destroyable {

	protected readonly _appService = inject(AppService);

	protected readonly _externalExtensionService = inject(ExternalExtensionService);

	private readonly __activatedRoute = inject(ActivatedRoute);

	private readonly __paymentsApiService = inject(PaymentApiService);

	private readonly __hostNotifier = inject(HostNotifierService);

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected _paymentMethod = this._session.paymentMethods
		.find(paymentMethod => paymentMethod.type.isCrypto && paymentMethod.provider === this.__activatedRoute.snapshot.params['provider'])!;

	protected _cryptoLogoAssets = 'assets/images/cryptos/';

	protected _amountCtrl = new FormControl();

	protected _emailCtrl?: FormControl<string | null>;

	protected _isSubmitting$ = new BehaviorSubject(false);

	protected _fiatCurrencies = this._paymentMethod
		.currencies!
		.map(currencyCode => new FiatCurrency(currencyCode));

	protected _initialFiatCurrency = this._session.currencyLock ? this._session.currency : this._fiatCurrencies[0];

	protected _fiatCurrency$ = new BehaviorSubject(this._initialFiatCurrency);

	protected _cryptoCurrencies = this._paymentMethod.crypto_currencies
		.map((v: CryptoCurrencyCode) => new CryptoCurrency(v));

	protected _initialCryptoCurrency = this._cryptoCurrencies[0];

	protected _cryptoCurrency$ = new BehaviorSubject<CryptoCurrency>(this._initialCryptoCurrency);

	protected get _cannotSubmit(): boolean {
		return !!this._emailCtrl?.invalid || this._amountCtrl.invalid;
	}

	constructor() {
		super();

		this.__hostNotifier.paymentMethodOpen({
			type: this._paymentMethod.typeInSnakeCase,
			provider: this._paymentMethod.provider,
		});

		if (this._isAdditional('email'))
			this._emailCtrl = new FormControl(this._session.email.value ?? null, this._session.email.validator);

		this.__updateTelemetryUserIdOnControlChanges();
	}

	protected _isAdditional(fieldName: typeof additionalInputFields[number]): boolean {
		return this._paymentMethod.additional_required_fields.includes(fieldName);
	}

	protected _submit(): void {
		if (this._emailCtrl?.invalid || this._amountCtrl.invalid || this._isSubmitting$.value)
			return;

		this._isSubmitting$.next(true);

		void this.__makePayment();
	}

	private async __makePayment(): Promise<void> {
		this._appService.alertBeforeUnload();

		try {
			const result = await firstValueFrom(
				this.__paymentsApiService.depositCrypto(this.__getPaymentRequestBody()),
			);

			this.__onPaymentResponse(result);
		} catch (error: unknown) {
			if (error instanceof BpError)
				this.__onPaymentError(error);
			else
				throw error;
		}
	}

	private __getPaymentRequestBody(): IRequestCryptoDeposit {
		return {
			type: this._paymentMethod.typeInSnakeCase,
			provider: this._paymentMethod.provider,
			currency: this._fiatCurrency$.value.code,
			/* eslint-disable @typescript-eslint/naming-convention */
			crypto_currency: this._cryptoCurrency$.value.code,

			address: this._session.address.value!,
			first_name: this._session.firstName.value,
			last_name: this._session.lastName.value,
			city: this._session.city.value,
			zip_code: this._session.zipCode.value,
			state: this._session.stateCode.value,
			country: this._session.countryCode.value,
			phone: this._session.phone.value,
			email: this._emailCtrl?.value ?? this._session.email.value,
			amount: Number(this._amountCtrl.value),

			order_id: this._session.orderId,
			affiliate_id: this._session.affiliateId,
			tracking_id: this._session.trackingId,
			ip: this._session.ip,
			amount_lock: this._session.amountLock,
			currency_lock: this._session.currencyLock,
			additional_data: this._appService.browserDetails,
			/* eslint-enable @typescript-eslint/naming-convention */
		};
	}

	private __onPaymentResponse(transaction: CryptoTransaction): void {
		this._appService.transaction = transaction;

		this._appService.paymentMethod = this._paymentMethod;

		this._appService.navigate([ '/payments/crypto-qr' ]);
	}

	private __onPaymentError(error: BpError<IApiTransaction>): void {
		this._appService.setError(error);

		if (error.payload && 'status' in error.payload)
			this._appService.transaction = new Transaction(error.payload);

		this._isSubmitting$.next(false);

		this._appService.navigate([
			error.status === ResponseStatusCode.TransactionDeclined
				? '/status/card-declined'
				: '/error',
		]);
	}

	private __updateTelemetryUserIdOnControlChanges(): void {
		this._emailCtrl?.valueChanges
			.pipe(
				filter(() => this._emailCtrl!.valid),
				takeUntilDestroyed(this),
			)
			.subscribe(email => email && void this._appService.telemetryIdentifyUser(email));
	}

}
