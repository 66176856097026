/* eslint-disable @typescript-eslint/naming-convention */

import { encodeURL } from 'js-base64';
import { timer } from 'rxjs';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, inject } from '@angular/core';

import { isEmpty } from '@bp/shared/utilities/core';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { ZoneService } from '@bp/frontend/rxjs';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { FADE_OUT } from '@bp/frontend/animations';

import {
	ThreeDsProcessingAuthenticationStatus, ThreeDsProcessingAuthenticateResponse, ThreeDsProcessingSession
} from '@bp/three-ds/shared/domains/three-ds-processing';

import { ThreeDsProcessingService } from '../services';
import { AppService } from '../../shared';

@Component({
	selector: 'bp-three-ds-processing-page',
	templateUrl: './three-ds-processing-page.component.html',
	styleUrls: [ './three-ds-processing-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_OUT ],
})
export class ThreeDsProcessingPageComponent extends Destroyable implements OnInit {

	protected readonly _environment = inject(EnvironmentService);

	protected readonly _appService = inject(AppService);

	private readonly __telemetry = inject(TelemetryService);

	private readonly __zoneService = inject(ZoneService);

	private readonly __cdr = inject(ChangeDetectorRef);

	private readonly __$host = <HTMLElement>inject(ElementRef).nativeElement;

	private readonly __threeDsProcessingService = inject(ThreeDsProcessingService);

	protected get _session(): ThreeDsProcessingSession {
		return this.__threeDsProcessingService.session!;
	}

	protected _isShowProcessingStub = true;

	private readonly __hiddenIframes: HTMLIFrameElement[] = [];

	constructor() {
		super();
	}

	ngOnInit(): void {
		if (this._session.threeDsProcessorBrowserInfoCollectionUrl)
			this.__executeBrowserInfoCollectionIframes();
		 else
			this.__telemetry.captureError('[3ds processing page]: three ds processor browser info collection url is not available');

		this.__handleThreeDsProcessorAuthorizationResponse();

		this.__whenAuthResultReadyReturnControlToPaymentProcessingService();

		this.__whenTimedOutReturnControlPaymentProcessingService();

	}

	private __executeBrowserInfoCollectionIframes(): void {
		this.__telemetry.log('[3ds processing page]: execute browser info collection iframes');

		this.__hiddenIframes.push(this.__executeHiddenIframe(
			this._session.threeDsProcessorBrowserInfoCollectionUrl,
			'browser-info-collection-iframe',
		));

		if (this._session.threeDsProcessorBrowserInfoCollectionMonitoringUrl) {
			this.__hiddenIframes.push(this.__executeHiddenIframe(
				this._session.threeDsProcessorBrowserInfoCollectionMonitoringUrl,
				'browser-info-collection-monitoring-iframe',
			));
		}
	}

	private __executeHiddenIframe(url: string, id: string): HTMLIFrameElement {
		const $iframe = document.createElement('iframe');

		$iframe.setAttribute('id', id);

		$iframe.classList.add('hidden-iframe');

		if (this._environment.isProduction) {
			$iframe.setAttribute('width', '0');

			$iframe.setAttribute('height', '0');

			$iframe.classList.add('invisible');
		} else {
			$iframe.setAttribute('width', '25%');

			$iframe.setAttribute('height', '25%');
		}

		$iframe.src = url;

		$iframe.addEventListener(
			'load',
			() => void this.__telemetry.log(`[3ds processing page]: ${ id } - hidden iframe loaded`),
		);

		$iframe.addEventListener(
			'error',
			() => void this.__telemetry.captureMessage('[3ds processing page]: hidden iframe error'),
		);

		this.__$host.insertAdjacentElement('beforeend', $iframe);

		return $iframe;
	}

	private __handleThreeDsProcessorAuthorizationResponse(): void {
		this.__threeDsProcessingService.authResponse$
			.pipe(takeUntilDestroyed(this))
			.subscribe(response => {
				this.__hideShownInStagingHiddenIframes();

				switch (response.status) {
					case ThreeDsProcessingAuthenticationStatus.c:
						this.__startChallengeFlow(response);
						break;

					case ThreeDsProcessingAuthenticationStatus.d:
						this.__startDecoupledAuthFlow(response);
						break;

					default:
						this.__telemetry.log('[3ds processing page]: no challenge return to payment processing service');

						void this.__returnControlToPaymentProcessingService();
						break;
				}
			});
	}

	private __whenAuthResultReadyReturnControlToPaymentProcessingService(): void {
		this.__threeDsProcessingService.authResultReady$
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this.__returnControlToPaymentProcessingService());
	}

	private __whenTimedOutReturnControlPaymentProcessingService(): void {
		timer(1000 * 60 * 9)
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => {
				this.__telemetry.log('[3ds processing page]: timed out');

				void this.__returnControlToPaymentProcessingService();
			});
	}

	private __startChallengeFlow(response: ThreeDsProcessingAuthenticateResponse): void {
		this.__telemetry.log('[3ds processing page]: start challenge flow');

		const challengeUrl = this.__tryAppendThreeDsProviderSessionDataToUrl(response.challengeUrl!);

		this.__executeChallengeIframe(challengeUrl);

		if (response.resultMonUrl)
			this.__threeDsProcessingService.monitorAuthResultViaPolling(response.resultMonUrl);
	}

	private __executeChallengeIframe(url: string): void {
		const $iframe = document.createElement('iframe');

		$iframe.setAttribute('id', 'challenge-iframe');

		$iframe.classList.add('challenge-iframe');

		$iframe.setAttribute('width', '100%');

		$iframe.setAttribute('height', '100%');

		$iframe.setAttribute('frameBorder', '0');

		$iframe.src = url;

		$iframe.addEventListener(
			'load',
			() => {
				this.__telemetry.log('[3ds processing page]: challenge iframe loaded');

				this._isShowProcessingStub = false;

				$iframe.classList.add('loaded');

				this._appService.markAppReady();

				this.__cdr.detectChanges();

				void parent.postMessage(
					{ event: '[bp]:hide-processing-stub' },
					'*',
				);
			},
			{
				once: true,
			},
		);

		this.__$host.insertAdjacentElement('beforeend', $iframe);
	}

	private __tryAppendThreeDsProviderSessionDataToUrl(url: string): string {
		const { threeDSSessionData } = this.__threeDsProcessingService.resultCallbackPayload!;

		if (isEmpty(threeDSSessionData))
			return url;

		// Important: We call encodeURIComponent() here to avoid invalid Base64Url
		// characters as well as convert any padding to URL friendly characters.
		return `${ url }&tds-session-data=${ encodeURL(threeDSSessionData) }`;
	}

	private __returnControlToPaymentProcessingService(): void {
		this.__telemetry.log('Returning control to payment service');

		if (this._appService.isEmbedded) {
			this.__telemetry.log('Emit 3ds auth result ready event');

			parent.postMessage(
				{
					event: '[bp]:three-ds-processor-auth-result-ready',
					transactionId: this._session.bpTransactionId,
				},
				'*',
			);
		} else {
			this.__telemetry.log('Navigate back to payment processing page');

			this.__zoneService.runInAngularZone(() => void this._appService.navigate(
				[ 'deposit-processing' ],
				{
					queryParams: {
						transaction_id: this._session.bpTransactionId,
						sessionId: this._session.checkoutSessionId,
						threeDsProcessorAuthResultReady: true,
						backendVersion: 'v2',
					},
				},
			));
		}

	}

	private __startDecoupledAuthFlow(response: ThreeDsProcessingAuthenticateResponse): void {
		this.__telemetry.log('[3ds processing page]: start decoupled auth flow');

		if (response.resultMonUrl)
			this.__threeDsProcessingService.monitorAuthResultViaPolling(response.resultMonUrl);
	}

	private __hideShownInStagingHiddenIframes(): void {
		this.__hiddenIframes.forEach($iframe => void $iframe.classList.add('hidden'));
	}
}
