import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@bp/frontend/environments';
import '@bp/frontend/startup';

import { AppModule } from './app/app.module';

redirectHostCheckoutToCheckoutHrefWhenCheckoutWasPutInsidePspIframe();

if (environment.isDeployed)
	enableProdMode();

void platformBrowserDynamic()
	.bootstrapModule(AppModule, {
		ngZoneEventCoalescing: true,
		ngZoneRunCoalescing: true,
	});

/**
 * Sometimes the back loads checkout status page inside our psp iframe, which doesn't make sense, so instead we take
 * the parent checkout iframe and redirect it to psp iframe checkout location.
 */
function redirectHostCheckoutToCheckoutHrefWhenCheckoutWasPutInsidePspIframe(): void {
	try {
		if (window.parent !== window.self && window.parent.origin && window.location.href.includes('/status')) {
			window.parent.postMessage({ event: '[bp]:redirect-master-checkout-to-status-page' }, '*');

			window.parent.bpAppService.navigateByUrl(
				window.location.href.replace(window.location.origin, ''),
			);
		}
	} catch {
	}
}
