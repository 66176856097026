import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { inject, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { BidiModule } from '@angular/cdk/bidi';

import { ensureType } from '@bp/shared/utilities/core';
import { Platform } from '@bp/shared/typings';

import { GlobalApiMockModule } from '@bp/frontend/api-mocking-global';
import { ApiMockPluginsManagerService, SharedServicesModule } from '@bp/frontend/services/core';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedFeaturesValidationModule } from '@bp/frontend/features/validation';
import { PLATFORM } from '@bp/frontend/services/environment';
import { SharedFeaturesRealUserMonitoringModule } from '@bp/frontend/features/apm-rum';

import { SharedModule } from '@bp/checkout-frontend/shared';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule, RootComponent } from './core';
import { PaymentsModule } from './payments';
import { HomeModule } from './home';
import { StatusModule } from './status';
import { DepositProcessingModule } from './deposit-processing';
import { TheeDSProcessingModule } from './three-ds-processing';
import { BloxModule } from './blox';
import { PaymentCardTokenModule } from './payment-card-token';

@NgModule({
	imports: [

		/**
		 * Note that we MUST import this module and provide direction as an attribute (not as CSS style,
		 * because BidiModule exports it as a directive) or directly for proper offsets calculation in
		 * Angular Material (e.g. in "outline" appearance).
		 */
		BidiModule,

		HttpClientModule,
		BrowserModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: () => new TranslateHttpLoader(inject(HttpClient), './assets/i18n/', '.json'),
			},
		}),

		SharedFeaturesRealUserMonitoringModule.forRoot(),
		SharedPipesModule.forRoot(),
		SharedServicesModule.forRoot(),
		SharedComponentsCoreModule.forRoot(),
		SharedFeaturesValidationModule.forRoot(),
		SharedModule.forRoot(),

		CoreModule,
		GlobalApiMockModule,
		BloxModule,
		PaymentsModule,
		PaymentCardTokenModule,
		HomeModule,
		StatusModule,
		DepositProcessingModule,
		TheeDSProcessingModule,
		AppRoutingModule,
	],
	providers: [
		{
			provide: PLATFORM,
			useValue: ensureType<Platform>('checkout-v1'),
		},
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
	],
	bootstrap: [ RootComponent ],
})
export class AppModule {
	constructor(private readonly _apiMockPluginsManagerService: ApiMockPluginsManagerService) {
		void this._apiMockPluginsManagerService.register(
			AppModule,
			async () => import('./api-mock-plugin/cashier-api-mock-plugin'),
		);
	}
}
