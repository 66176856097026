/* eslint-disable @typescript-eslint/naming-convention */

import { firstValueFrom } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { TelemetryService } from '@bp/frontend/services/telemetry';

import { ThreeDsProcessingSession } from '@bp/three-ds/shared/domains/three-ds-processing';

import { ThreeDsProcessingApiService } from './three-ds-processing-api.service';
import { ThreeDsProcessingService } from './three-ds-processing.service';

@Injectable({
	providedIn: 'root',
})
export class ThreeDsProcessingSessionResolver {

	private readonly __threeDsProcessingApiService = inject(ThreeDsProcessingApiService);

	private readonly __threeDsProcessingService = inject(ThreeDsProcessingService);

	async resolve(route: ActivatedRouteSnapshot): Promise<ThreeDsProcessingSession> {
		TelemetryService.setTags({ service: '3ds' });

		void parent.postMessage(
			{ event: '[bp]:show-processing-stub' },
			'*',
		);

		const session = await firstValueFrom(
			this.__threeDsProcessingApiService.get(route.params['bpTransactionId']),
		);

		this.__threeDsProcessingService.setSession(session);

		return session;
	}

}
