import { CountryCode, parsePhoneNumberWithError, ParseError } from 'libphonenumber-js';
import { isString } from 'lodash-es';

import { IValidatorFunc, Validators } from '@bp/shared/features/validation/models';
import { Countries, Country, State } from '@bp/shared/models/countries';
import { NonFunctionPropertyNames } from '@bp/shared/typings';

export class CheckoutValidators {

	static amount: IValidatorFunc = input => Validators.number(input) ? { ccAmount: null } : null;

	static countryCode: IValidatorFunc<string | null> = ({ value }) => {
		if (Validators.isEmptyValue(value))
			return null; // don't validate empty values to allow optional controls

		return Countries.includesCode(value)
			? null
			: { phoneCountryCode: null };
	};

	static stateByCode(country: Country | null): IValidatorFunc {
		return CheckoutValidators.state(country, 'code');
	}

	static stateByName(country: Country | null): IValidatorFunc {
		return CheckoutValidators.state(country, 'name');
	}

	static state(country: Country | null, by: NonFunctionPropertyNames<State>): IValidatorFunc {
		return ({ value }) => !country?.states || country.states.some(s => s[by] === value)
			? null
			: { state: null };
	}

	static countryZipCode(country?: Country | null): IValidatorFunc {
		return country?.code === 'US'
			? Validators.pattern(/^\d{5}-\d{4}$|^\d{5}$/u)
			: Validators.minLength(3);
	}

	static phone: IValidatorFunc = ({ value }) => {
		if (Validators.isEmptyValue(value))
			return null; // don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`phone` validator expects string to be validated');

		return CheckoutValidators.isValidPhoneNumber(value)
			? null
			: { phoneParseError: null };
	};

	static isValidPhoneNumber(phone: string, countryCode?: CountryCode): boolean {
		try {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const phoneNumber = parsePhoneNumberWithError(phone, countryCode);
		} catch (error: unknown) {
			 if (error instanceof ParseError)
				return false;

			// eslint-disable-next-line rxjs/throw-error
			throw error;
		}

		return true;
	}
}
