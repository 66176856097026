
import {
	Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, inject
} from '@angular/core';

import { FADE, FADE_IN } from '@bp/frontend/animations';

import { PaymentOptionInstance } from '@bp/checkout-frontend/models';
import { AppService, PaymentOptionInstancesManager } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-payment-option-instances-breakdown',
	templateUrl: './payment-option-instances-breakdown.component.html',
	styleUrls: [ './payment-option-instances-breakdown.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN, FADE ],
})
export class PaymentOptionInstancesBreakdownComponent implements OnDestroy {

	protected readonly _paymentOptionInstancesManager = inject(PaymentOptionInstancesManager);

	private readonly __appService = inject(AppService);

	private readonly __cdr = inject(ChangeDetectorRef);

	@Input() disabled!: boolean;

	get session() {
		return this.__appService.session;
	}

	get activeIndex() {
		return this._paymentOptionInstancesManager.activeInstance?.index ?? 0;
	}

	cachedActiveInstance?: PaymentOptionInstance;

	constructor() {
		this._paymentOptionInstancesManager.setCdr(this.__cdr);
	}

	ngOnDestroy(): void {
		this._paymentOptionInstancesManager.removeCdr(this.__cdr);
	}

	trackBy(index: number, name: PaymentOptionInstance): number {
	  return name.index;
	}
}
