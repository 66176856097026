/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, ChangeDetectionStrategy, inject } from '@angular/core';

import { PaymentOptionType } from '@bp/frontend/models/business';

import { IPaymentMethod } from '@bp/checkout-frontend/models';
import { AppService } from '@bp/checkout-frontend/providers';

const METHODS_IMGS_FOLDER = 'assets/images/payment-methods';

@Component({
	selector: 'bp-payment-option-logo',
	templateUrl: './payment-option-logo.component.html',
	styleUrls: [ './payment-option-logo.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentOptionLogoComponent {

	protected _appService = inject(AppService);

	@Input() paymentOption!: IPaymentMethod;

	protected readonly _PaymentOptionType = PaymentOptionType;

	protected readonly _staticPaywithCCLogo = `${ METHODS_IMGS_FOLDER }/visa-mastercard-amex.svg`;

	protected get _session(): AppService['session'] {
		return this._appService.session;
	}

	protected readonly _durationOfSlide = 3; // seconds

}
