/* eslint-disable @typescript-eslint/naming-convention */
import { catchError, first, map, repeat, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { NEST_PATH_SEGMENT } from '@bp/shared/models/core';

import { bypassAuthorizationHeaderCheck, setJsonNamingStrategyHeader, skipAllHttpConfigHeaders } from '@bp/frontend/services/http';

import {
	PATH_SEGMENTS, ThreeDsProcessingAuthenticateRequest, ThreeDsProcessingSession, ThreeDsProcessingSessionDTO, ThreeDsProcessingAuthenticateResponse, ThreeDsProcessingAuthenticateResponseDTO
} from '@bp/three-ds/shared/domains/three-ds-processing';

@Injectable({
	providedIn: 'root',
})
export class ThreeDsProcessingApiService {

	private readonly __http = inject(HttpClient);

	private readonly __requestContext = bypassAuthorizationHeaderCheck(
		setJsonNamingStrategyHeader('camelcase'),
	);

	get(bpTransactionId: string): Observable<ThreeDsProcessingSession> {
		return this.__http
			.get<ThreeDsProcessingSessionDTO>(
			`${ NEST_PATH_SEGMENT }/${ PATH_SEGMENTS.threeDsProcessing }/${ bpTransactionId }`,
			{ context: this.__requestContext },
		)
			.pipe(map(dto => new ThreeDsProcessingSession(dto)));
	}

	authenticate(request: ThreeDsProcessingAuthenticateRequest): Observable<ThreeDsProcessingAuthenticateResponse> {
		return this.__http
			.post<ThreeDsProcessingAuthenticateResponseDTO>(
			`${ NEST_PATH_SEGMENT }/${ PATH_SEGMENTS.threeDsProcessing }/${ PATH_SEGMENTS.authenticate }`,
			request,
			{ context: this.__requestContext },
		)
			.pipe(map(dto => new ThreeDsProcessingAuthenticateResponse(dto)));
	}

	pollMonitoringUrlUntilAuthResultReady(monitoringUrl: string): Observable<void> {
		return this.__http
			.get<{ event?: 'AuthResultNotReady' | 'AuthResultReady' }>(
			monitoringUrl,
			// avoid adding http config headers to the external 3ds monitoring url request
			{ context: skipAllHttpConfigHeaders() },
		)
			.pipe(
				catchError(() => EMPTY), // swallow errors
				repeat({ delay: 5000 }),
				tap(({ event }) => {
					if (event !== 'AuthResultNotReady' && event !== 'AuthResultReady')
						throw new Error(`Got unexpected monitoring url response event ${ event }`);
				}),
				first(({ event }) => event === 'AuthResultReady'),
				map(() => void 0),
			);
	}
}
